<template>
    <div class="all">
        <div class="background">
            <img v-if="enterpriseObj" class="logo" :src="enterpriseObj.config.loginBackground"
                style="width: 100%;height: 100%;" alt="" />
            <img v-else src="../../assets/login/bg-login.png" style="width: 100%;height: 100%;" alt="" />
        </div>

        <div class="content">
            <img v-if="enterpriseObj" class="logo" :src="enterpriseObj.config.homeLogo" alt="" width="434px"
                height="100px" />
            <img v-else class="logo" src="@/assets/home/icon-LOGO.png" alt="" />
            <!--<img :src="logoImg" width="434px" height="100px">-->
            <div v-if="loginBoxShowFlag" class="content-login">
                <div class="content-login-header">
                    <img src="../../assets/login/xian-left.png">
                    <h3>登录</h3>
                    <img src="../../assets/login/xian-right.png">
                </div>

                <div class="login-input" style="width: 100%">
                    <el-tabs>
                        <span>账号</span>
                        <el-input placeholder="请输入用户名或手机号" v-model="loginDate.account" clearable>
                        </el-input>
                    </el-tabs>
                </div>
                <div class="login-input" style="width: 100%">
                    <span>密码</span>
                    <el-input placeholder="请输入密码" clearable v-model="loginDate.pwd" show-password></el-input>
                </div>
                <div class="login-input" style="width: 100%">
                    <span>验证码</span>
                    <el-input placeholder="请输入验证码" clearable v-model="loginDate.captcha" style="width: 200px;"></el-input>
                    <img style="float: right;" @click="getUuid" :src="codeUrl">
                </div>
                <div class="login-auto" style="width: 100%;">
                    <div>
                        <!-- <el-checkbox v-model="checked">勾选同意</el-checkbox> -->
                        登录即同意
                        <span @click="userAgreement()" style="color: #409eff;cursor: pointer;">《用户协议》</span>
                        <span @click="privacyPolicy()" style="color: #409eff;cursor: pointer;">《隐私政策》</span>
                    </div>
                    <div>
                        <span style="cursor: pointer;" @click="loginBoxShowFlag = !loginBoxShowFlag">验证码登录</span>
                    </div>
                </div>

                <span style="cursor: pointer;" class="btn" :loading="loading" @click="goLogin1()">登录</span>

                <div class="login-auto" style="width: 100%">
                    <div class="login-toenroll">
                        还没有账户?
                        <span @click="goEnrollYe()" style="cursor: pointer;">注册</span>
                    </div>
                    <div>
                        <div style="display: inline-block">已有帐号?</div>
                        <span @click="goFoeget()" style="cursor: pointer;">忘记密码</span>
                    </div>
                </div>

            </div>

            <!--验证码登录-->
            <div v-else class="content-login">
                <div class="content-login-header">
                    <img src="@/assets/login/xian-left.png" alt="">
                    <h3>登录</h3>
                    <img src="@/assets/login/xian-right.png" alt="">
                </div>
                <div class="login-input" style="width: 100%">
                    <span>手机号</span>
                    <el-input placeholder="请输入手机号" v-model="phoneLoginMsg.phone" clearable>
                    </el-input>
                </div>
                <!-- <div class="login-input" style="width: 100%">
                    <span>验证码</span>
                    <el-input placeholder="请输入验证码" clearable v-model="phoneLoginMsg.captcha"
                        style="width: 200px;"></el-input>
                    <img style="float: right;" @click="getUuid" :src="codeUrl">
                </div> -->
                <div class="login-input" style="width: 100%;">
                    <span>验证码</span>
                    <div class="login-ma" style="display: flex;">
                        <el-input placeholder="请输入验证码" style="width: 200px;" clearable
                            v-model="phoneLoginMsg.captcha"></el-input>
                        <el-button type="primary" style="margin-left: 30px" @click="openCodePage"
                            :disabled="totalTime < 60">
                            {{ content }}
                        </el-button>
                    </div>
                </div>
                <div class="login-auto" style="width: 100%;">
                    <div>
                        <!-- <el-checkbox v-model="checked">勾选同意</el-checkbox> -->
                        登录即同意
                        <span @click="userAgreement()" style="color: #409eff;cursor: pointer;">《用户协议》</span>
                        <span @click="privacyPolicy()" style="color: #409eff;cursor: pointer;">《隐私政策》</span>
                    </div>
                    <div>
                        <span
                            style="font-size: 14px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #1371E7;cursor: pointer;"
                            @click="loginBoxShowFlag = !loginBoxShowFlag" class="login-scroll">
                            密码登录
                        </span>
                    </div>
                </div>
                <span class="btn" @click="goLogin">登录</span>


            </div>
        </div>
        <el-dialog :before-close="closeCodePage" title="请输入验证码" :visible.sync="dialogVisible" width="434px"
            style="margin-top: 16%;">
            <div>
                <!-- <span style="display: block;">验证码</span> -->
                <el-input placeholder="请输入验证码" clearable v-model="phoneLoginMsg.captcha"
                    style="width: 200px;text-align: center;"></el-input>
                <img style="float: right;" @click="getUuid" :src="codeUrl">
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeCodePage()">取 消</el-button>
                <el-button type="primary" @click="getCountdown">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { adornUrl, httpNPGet, httpPost, httpGet } from "@/utils/httpRequest";

export default {
    name: "login",
    data() {
        return {
            activeName: 'first',
            loginDate: {
                uuid: '',
                account: '',
                pwd: '',
                captcha: '',
            },
            userId: '',
            userName: '',
            headImg: '',
            checked: false,
            loading: false, //‘提交’按钮的loading状态
            logoImg: '',
            //登录框显示标志
            loginBoxShowFlag: true,
            //手机验证码登录
            phoneLoginMsg: {
                uuid: '',
                phone: '',
                captcha: '',
            },
            totalTime: 60,
            content: "发送验证码",
            enterpriseObj: {},
            codeUrl: '',
            dialogVisible: false,
        }
    },
    created() {
        this.enterpriseObj = this.$store.getters.enterpriseObj;
    },
    mounted() {
        // 自动登录
        // this.getCookie()
        // this.logoImg = localStorage.getItem("logoImg")
        this.getUuid()
    },
    methods: {
        // 用户协议
        userAgreement() {
            let routeUrl = this.$router.resolve({
                path: '/userAgreement'
            })
            window.open(routeUrl.href, '_blank')
        },
        // 隐私政策
        privacyPolicy() {
            let routeUrl = this.$router.resolve({
                path: '/privacyPolicy'
            })
            window.open(routeUrl.href, '_blank')
        },
        // 获取uuid
        getUuid() {
            httpNPGet("/captcha/uuid", (res) => {
                let uuid = res.data.uuid;
                this.loginDate.uuid = uuid;
                this.phoneLoginMsg.uuid = uuid;
                this.codeUrl = adornUrl("/captcha/img?uuid=" + uuid);

            }, (err) => {

            })
        },
        //验证码登录
        goLogin() {
            if (this.phoneLoginMsg.phone == '') {
                this.$message.error('请输入手机号');
            } else if (this.phoneLoginMsg.captcha == '') {
                this.$message.error('请输入验证码');
            } else {
                let data = {
                    ...this.phoneLoginMsg,
                    enterpriseId: this.$store.getters.enterpriseObj.id
                };
                delete data.uuid
                httpGet("/app/app-user/login-phone",
                    data
                    , (res) => {
                        // console.log(res)
                        this.userId = res.data.userId
                        // this.$cookies.set("oatoken",res.data.token,'1d')
                        // console.log(res.data)
                        localStorage.setItem("oatoken", res.data.token)
                        localStorage.setItem("tokenPass",res.data.tokenPass)
                        localStorage.setItem("userHeadImg", res.data.headImg)
                        // console.log(localStorage.getItem("oatoken"))
                        // this.$message.success(this.localStorage.getItem("userName") + "欢迎")
                        //登录成功后，跳转到首页页面
                        if (res.data.identityFlag == true) {
                            this.$router.push({
                                path: '/',
                            })
                        } else {
                            this.$router.push('/authentication')
                        }
                        // this.$router.push({ path: this.redirect || '/' })
                        // this.loading = false
                        // console.log(res)
                        // this.$router.push('/login')
                    }, (err) => {
                        if (err.msg == "身份认证为空") {
                            this.$message.error(err.msg)
                            this.$router.push("/Enroll")
                        }
                        if (err.code == 500) {
                            if (err.msg == "token异常，请重新登录") {
                                localStorage.clear();
                                if (!localStorage.getItem("userId")) {
                                    this.$message.error('登录过期，请重新登录!')
                                    this.$router.push("/login")
                                }
                            }
                        }
                        // // console.log(err)
                        if (err.msg == "身份认证为空") {
                            this.$message.error("请先完成身份认证")
                            this.$router.push("/enrollren")
                        }
                        if (err.msg == '账户不存在') {
                            this.$message.error('账户不存在,请注册');
                            this.loginDate.account = '';
                            this.loginDate.pwd = '';
                        }
                        if (err.msg == '密码错误') {
                            this.$message.error('密码错误');
                            this.loginDate.pwd = '';
                        }
                        this.$message.error(err.msg)
                        throw err
                    })
            }


        },
        // 打开验证码弹窗
        openCodePage() {
            this.codeUrl = "";
            this.getUuid()
            if ((this.phoneLoginMsg.phone + "").length == 11) {
                this.dialogVisible = true;
            } else {
                // console.log((this.phoneLoginMsg));
                this.$message.error('请填写正确的手机号');
            }
        },
        // 关闭验证码弹窗
        closeCodePage() {
            this.dialogVisible = false;
            this.phoneLoginMsg.captcha = ''
        },
        // 60s 倒计时
        getCountdown() {
            this.getMa();
            let clock = window.setInterval(() => {
                this.content = this.totalTime + 's后重新发送';
                this.totalTime--;
                if (this.totalTime < 0) {
                    this.totalTime = 60;
                    this.content = "重新发送验证码";
                    window.clearInterval(clock);
                }
            }, 1000);
        },
        // 验证码
        getMa() {
            httpPost("/captcha/send-login", this.phoneLoginMsg
                , (res) => {
                    this.$message.success(res.msg);
                    this.dialogVisible = false;
                    this.phoneLoginMsg.captcha = ''
                }, (err) => {
                    this.$message.error(err.msg);
                    this.totalTime = 0;
                })
        },
        goFoeget() {
            this.$router.push('/modify')
        },
        goEnrollYe() {
            this.$router.push('/enroll')
        },
        // 自动登录
        // 登录(保存tiken、配置axios、重定向)
        handleSubmit() {
            this.$refs.loginDate.validate((valid) => {
                if (valid) {
                    this.loading = true
                    //根据checked来判断是否存cookie
                    this.rememberUser()
                    this.$store
                        .dispatch('/login', this.loginDate) //通过username、password获取token，更新vuex及cookie中token值
                        .then(() => {
                            this.$router.push({ path: this.redirect || '/' })
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                        })
                } else {
                    // console.log('提交失败！！！')
                    return false
                }
            })
        },
        // 下次自动登录——记住账号密码相关操作
        // 记住用户
        rememberUser() {
            const that = this
            //判断复选框是否被勾选 勾选则调用配置cookie方法
            if (that.checked == true) {
                //传入账号名，密码，和保存天数三个参数
                that.setCookie(that.loginDate.account, that.loginDate.pwd, 7)
            } else {
                that.clearCookie() //清空Cookie
            }
        },

        goLogin1() {
            this.loginDate.enterpriseId = this.$store.getters.enterpriseObj.id
            // console.log(this.loginDate)
            if (this.loginDate.account == '' || this.loginDate.pwd == '') {
                this.$message.error('请输入账号或密码');
            } else {
                httpGet("/app/app-user/login",
                    {
                        ...this.loginDate,
                    }
                    , (res) => {
                        // console.log(res)
                        this.userId = res.data.userId
                        //将后端传递的token存到浏览器的cookie中，key=oatoken，过期时间7天
                        // this.$cookies.set("oatoken",res.data.token,'1d')
                        // console.log(res.data)
                        localStorage.setItem("oatoken", res.data.token)
                        localStorage.setItem("tokenPass",res.data.tokenPass)
                        // localStorage.setItem("tokenPass",new Date().valueOf()+10000)
                        //登录成功后，跳转到首页页面
                        if (res.data.identityFlag == true) {
                            this.$router.push({
                                path: '/',
                            })
                        } else {
                            this.$router.push('/authentication')
                        }

                        // this.$router.push({
                        //     path: '/',
                        // })
                        // this.$router.push({ path: this.redirect || '/' })
                        // this.loading = false
                        // console.log(res)
                        // this.$router.push('/login')
                    }, (err) => {
                        if (err.msg == "身份认证为空") {
                            this.$message.error(err.msg)
                            this.$router.push("/Enroll")
                        }
                        if (err.code == 500) {
                            if (err.msg == "token异常，请重新登录") {
                                localStorage.clear();
                                if (!localStorage.getItem("userId")) {
                                    this.$message.error('登录过期，请重新登录!')
                                    this.$router.push("/login")
                                }
                            }
                        }
                        // // console.log(err)
                        if (err.msg == "身份认证为空") {
                            this.$message.error("请先完成身份认证")
                            this.$router.push("/enrollren")
                        }
                        if (err.msg == '账户不存在') {
                            this.$message.error('账户不存在,请注册');
                            this.loginDate.account = '';
                            this.loginDate.pwd = '';
                        }
                        if (err.msg == '密码错误') {
                            this.$message.error('密码错误');
                            this.loginDate.pwd = '';
                        }
                        this.$message.error(err.msg)
                        throw err
                    })
            }
            // console.log(this.loginDate)

            // httpNPGet("/app/app-user/login",this.newDate, (data) => {
            //   console.log('-------------', data)
            //   // this.loginMsg.uuid = data.data.uuid;
            //   //更新验证码
            //   // this.codeUrl = adornUrl("/captcha/img?uuid=" + this.loginMsg.uuid);
            // })
        }
    }
}
</script>

<style lang="scss" scoped>
.all {

    .background {
        width: 100%;
        height: 100%;
        /**宽高100%是为了图片铺满屏幕 **/
        z-index: -1;
        position: absolute;
    }

    .content {
        width: 434px;
        height: 570px;
        //box-sizing: border-box;
        //background-color: #fff;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        .content-login {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 434px;
            box-sizing: border-box;
            padding: 32px 48px;
            margin-top: 25px;
            background: #FFF;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.01), 0px 4px 11px 0px rgba(0, 0, 0, 0.02), 0px 10px 27px 0px rgba(0, 0, 0, 0.03);
            border-radius: 2px 2px 2px 2px;
            border-radius: 20px;

            .content-login-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 28px;
                    padding: 0 48px;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                }

                img {
                    width: 92px;
                    height: 2px;
                    background: linear-gradient(287deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
                    border-radius: 0px 0px 0px 0px;
                }
            }

            .login-input {
                text-align: left;

                span {
                    display: block;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.85);
                    padding-bottom: 8px;
                }
            }

            .login-auto {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 5px;

                span {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #1371E7;
                }
            }

            .login-toenroll {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #667280;

                span {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #1371E7;
                }
            }

            .btn {
                display: block;
                width: 339px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #1371E7;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
                border-radius: 2px 2px 2px 2px;
                cursor: pointer;
            }
        }
    }
}
</style>
